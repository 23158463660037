
//请求类工具
import axios from 'axios'


const request = axios.create({
    baseURL:'https://wingsamui.cn',
    timeout:5000
})


//请求拦截
request.interceptors.request.use(config=>{
    // config.headers.Authorization = 'token'
    return config 
})


//相应拦截
request.interceptors.response.use(res=>{
    return res.data.data
})


export default request