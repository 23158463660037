import request from '../util/request'

export const getNewsList = (data) => { 
    return request({
        url: "/pc/news/getNewsList",
        method: "post",
        data:data
    })
}
export const getNewsById = (params) => { 
    return request({
        // url: `/pc/news/getNewsById?newsId=${data.newsId}`,
        url: '/pc/news/getNewsById',
        method: "get",
        params 
    })
}