<template>
  <div>
    <Header></Header>
    <div class="lone">
      <div class="banner">
        <div class="main_left">
          <div class="nav" v-for="(item, index) in navarr[0][num]" :key="index" @click="router(index)">
            <div class="flex_row">
              <div>{{ item }}</div>
              <div>></div>
            </div>
          </div>
        </div>

        <div class="main_right">
          <div class="manager_box">
            <div class="img_header">
              <img :src="encnimg[num].imgurl" alt="" />
            </div>
            <div class="fs30">{{ newstitle[num] }}</div>
            <div class="particulars-box">
              <div class="particulars-title">{{ newData[num].title }}</div>
              <div class="particulars-time">时间:{{ newData[num].releaseTime }}</div>
              <div class="particulars-views">浏览量:{{ newData[num].views }}</div>
              <div v-html="newData[num].briefIntroduction"></div>

            </div>
          </div>
        </div>
      </div>
    </div>

    <Footer></Footer>
  </div>
</template>

<script>
import Header from "../components/Herder.vue";
import Footer from "../components/Footer.vue";
import { mapState } from "vuex";
import { getNewsById } from "../api/new"
export default {
  data() {
    return {
      newstitle: {
        0: '新闻中心',
        1: 'news'
      },
      newData: {
        0: {
          image: "",
          title: "",
          content: "",
          views: "",
          briefIntroduction: "",
          type: "",
        },
        1: {
          title: "",
          content: "",
          briefIntroduction: "",
        }
      },
      navarr: [
        {
          0: ["新闻中心"],

          1: ["news"],
        },
      ],
      encnimg: [
        {
          imgurl: require("../img/headerimg.png"),
        },
        {
          imgurl: require("../img/headerenimg.png"),
        },
      ],
    };
  },
  components: { Header, Footer },
  computed: {
    ...mapState(["num"]),
  },
  mounted() {
    this.send()
  },
  methods: {
    async send() {
      const res = await getNewsById({ newsId: this.$route.query.id })
      this.newData[0].image = res.image
      this.newData[0].title = res.title
      this.newData[0].content = res.content
      this.newData[0].views = res.views
      this.newData[0].briefIntroduction = res.briefIntroduction
      this.newData[0].type = res.type
      this.newData[1].title = res.englishTitle
      this.newData[1].content = res.englishContent
      this.newData[1].briefIntroduction = res.englishBriefIntroduction
      this.newData[0].releaseTime = res.releaseTime
    }
  },
};
</script>

<style lang="less" scoped>
/deep/section {
  margin: 0 auto;

  img {
    margin: 0 auto;
  }
}

/deep/p img {
  margin: 0 auto !important;
  width: 90%;
}
/deep/iframe{
  height: 500px !important;
}
/deep/div p {
  width: 8rem;
  font-size: 0.16rem;
  margin: 0.20rem auto;
  line-height: 0.20rem;
}

.particulars-title {
  font-size: 0.24rem;
  text-align: center;
  margin-bottom: 0.36rem;
}

.particulars-time {
  font-size: 0.14rem;
  text-align: center;
  color: #666666;
}

.particulars-views {
  font-size: 0.14rem;
  text-align: center;
  color: #666666;
  margin: 0.30rem auto;
}

.content {
  text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  width: 20em;
}

h3 {
  font-size: 0.2rem;
  margin-bottom: 0.4rem;
  display: flex;
  justify-content: space-between;

  div {
    font-size: 0.14rem;
    color: #666;
  }
}

.newBox_left {
  width: 1.5rem;
  height: 1.5rem;
  // background-color: blueviolet;
  display: flex;

  img {
    width: 0.9rem;
    height: 0.9rem;
    margin: auto;
  }
}

.newBox_right {
  flex: 1;
  // background-color: pink;
  height: 100%;
  padding: 0.3rem 0.2rem 0.2rem 0;
  box-sizing: border-box;
}

.newsBox {
  width: 8rem;
  height: 1.55rem;
  margin: 0 auto;
  background-color: white;
  margin-bottom: 0.3rem;
  display: flex;
  align-items: center;
}

.img_header {
  height: 0.8rem;
  width: 4.26rem;
  margin: 0 auto;

  img {
    width: 100%;
  }
}

.fs30 {
  margin: 0.8rem auto;
  font-size: 0.3rem;
  text-align: center;
}

.lone {
  width: 100%;
  background-color: #f3f3f4;
  overflow: hidden;
}

.flex_row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  line-height: 0.65rem;
  padding-left: 0.5rem;
  padding-right: 0.2rem;
}

.nav {
  width: 2.7rem;
  height: 0.65rem;
  background: white;
  margin-bottom: 0.05rem;
}

.nav:hover {
  background-color: #a08e75;
  color: white;
}

.banner {
  width: 12.6rem;

  margin: 1rem auto;
  display: flex;
}

.main_left {
  width: 3.47rem;
  height: 100%;
  border-right: 0.02rem solid #dad5ce;
}

.main_right {
  flex: 1;
}
</style>