import { render, staticRenderFns } from "./newparticulars.vue?vue&type=template&id=1729aea8&scoped=true&"
import script from "./newparticulars.vue?vue&type=script&lang=js&"
export * from "./newparticulars.vue?vue&type=script&lang=js&"
import style0 from "./newparticulars.vue?vue&type=style&index=0&id=1729aea8&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1729aea8",
  null
  
)

export default component.exports